//header actions
export const HEADER_LOGO_SUCCESS                 = 'HEADER_LOGO_SUCCESS';
export const HEADER_WISHLIST_SUCCESS             = 'HEADER_WISHLIST_SUCCESS';
export const HEADER_CART_SUCCESS                 = 'HEADER_CART_SUCCESS';
export const HEADER_USERLOGIN_SUCCESS            = 'HEADER_USERLOGIN_SUCCESS';
export const HEADER_SEARCH_SUCCESS            	 = 'HEADER_SEARCH_SUCCESS';
export const HEADER_MENU_LOGO_SUCCESS            = 'HEADER_MENU_LOGO_SUCCESS';
export const HEADER_CURRENCY_SUCCESS             = 'HEADER_CURRENCY_SUCCESS';
//menu actions
export const MENUS_SUCCESS                       = 'MENUS_SUCCESS';
export const MENUS_INFORMATION_SUCCESS           = 'MENUS_INFORMATION_SUCCESS';
export const MENUS_CUSTOMSTORE_SUCCESS           = 'MENUS_CUSTOMSTORE_SUCCESS';

//banner actions
export const BANNER_SUCCESS                      = 'BANNER_SUCCESS';

//home actions
export const HOME_CATEGORY_SUCCESS               = 'HOME_CATEGORY_SUCCESS';
export const HOME_LATEST_SUCCESS                 = 'HOME_LATEST_SUCCESS';
export const HOME_TRENDING_SUCCESS               = 'HOME_TRENDING_SUCCESS';

//Information actions
export const INFORMATION_SUCCESS                 = 'INFORMATION_SUCCESS';
export const INFORMATION_ABOUT_SUCCESS           = 'INFORMATION_ABOUT_SUCCESS';
export const INFORMATION_CONTACT_SUCCESS         = 'INFORMATION_CONTACT_SUCCESS';
export const STORELOCATOR_SUCCESS                = 'STORELOCATOR_SUCCESS';
export const DIALOG_OPEN                         = 'DIALOG_OPEN';
export const SELLER_AGREEMENT_DATA               = 'SELLER_AGREEMENT_DATA';
export const INFORMATION_POLICY_SUCCESS          = 'INFORMATION_POLICY_SUCCESS';

//Login actions
export const LOGIN_OPTFORM_SUCCESS               = 'LOGIN_OPTFORM_SUCCESS';
export const LOGIN_VERIFYOPTFORM_SUCCESS         = 'LOGIN_VERIFYOPTFORM_SUCCESS';
export const LOGIN_PASSWORDOPTFORM_SUCCESS       = 'LOGIN_PASSWORDOPTFORM_SUCCESS';
export const LOGIN_SUCCESS                       = 'LOGIN_SUCCESS';
export const LOGIN_CUSTOMER_TYPE_SUCCESS         = 'LOGIN_CUSTOMER_TYPE_SUCCESS';
export const LOGIN_COUNTRY_SUCCESS               = 'LOGIN_COUNTRY_SUCCESS';
export const OPT_POPUP                           = 'OPT_POPUP';
export const LOGIN_POPUP                         = 'LOGIN_POPUP';
export const REGISTER_POPUP                      = 'REGISTER_POPUP';
export const SUCCESS_POPUP                       = 'SUCCESS_POPUP';
export const DESIGN_POPUP                        = 'DESIGN_POPUP';

//Category actions
export const CATEGORY_PRODUCT_SUCCESS            = 'CATEGORY_PRODUCT_SUCCESS';
export const CATEGORY_PRODUCT_PAGINATION_SUCCESS = 'CATEGORY_PRODUCT_PAGINATION_SUCCESS';
export const CATEGORY_FILTER_PATH_SUCCESS        = 'CATEGORY_FILTER_PATH_SUCCESS';
export const CATEGORY_FILTER_SUCCESS             = 'CATEGORY_FILTER_SUCCESS';

export const CATEGORY_PATH_KEYWORD_SUCCESS       = 'CATEGORY_PATH_KEYWORD_SUCCESS';
export const CATEGORY_PRODUCT_ERROR              = 'CATEGORY_PRODUCT_ERROR';

//Product actions
export const PRODUCT_SUCCESS                     = 'PRODUCT_SUCCESS';
export const PRODUCT_ERROR                       = 'PRODUCT_ERROR';
export const PRODUCT_RELATED_SUCCESS             = 'PRODUCT_RELATED_SUCCESS';
export const QUESTION_POPUP                      = 'QUESTION_POPUP';

//Cart actions
export const CART_SUCCESS                        = 'CART_SUCCESS';
export const CART_UPDATE_SUCCESS                 = 'CART_UPDATE_SUCCESS';
export const CART_UPDATE_SHIPPPING_SUCCESS       = 'CART_UPDATE_SHIPPPING_SUCCESS';
export const CART_ERROR                          = 'CART_ERROR';
export const CART_CONFIRM_POPUP                  = 'CART_CONFIRM_POPUP';
export const CART_ESTIMATE_POPUP                 = 'CART_ESTIMATE_POPUP';

//Account actions
export const USER_DETAIL_SUCCESS                    = 'USER_DETAIL_SUCCESS';
export const BANK_DETAIL_SUCCESS                    = 'BANK_DETAIL_SUCCESS';
export const USER_ADDRESS_LIST_SUCCESS              = 'USER_ADDRESS_LIST_SUCCESS';
export const USER_ADDRESS_FORM_SUCCESS              = 'USER_ADDRESS_FORM_SUCCESS';
export const USER_SUCCESS_ADDRESS_LIST_DATA         = 'USER_SUCCESS_ADDRESS_LIST_DATA';
export const ACCOUNT_SUCCESS_MESSAGE 		        = 'ACCOUNT_SUCCESS_MESSAGE';
export const ORDER_DETAIL_SUCCESS 		            = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_PAGINATION_SUCCESS        = 'ORDER_DETAIL_PAGINATION_SUCCESS';
export const ORDER_DETAIL_INFO_SUCCESS              = 'ORDER_DETAIL_INFO_SUCCESS';
export const ORDER_PRODUCT_DETAIL_SUCCESS           = 'ORDER_PRODUCT_DETAIL_SUCCESS';
export const ORDER_SHIPPING_PREFERENCES_SUCCESS     = 'ORDER_SHIPPING_PREFERENCES_SUCCESS';
export const ORDER_PAYMENT_INFO_SUCCESS             = 'ORDER_PAYMENT_INFO_SUCCESS';
export const WISHLIST_PRODUCT_SUCCESS		        = 'WISHLIST_PRODUCT_SUCCESS';
export const RECENT_VIEW_PRODUCT_SUCCESS	        = 'RECENT_VIEW_PRODUCT_SUCCESS';
export const POST_YOUR_REQUIRMENT_CATEGORY_SUCCESS	= 'POST_YOUR_REQUIRMENT_CATEGORY_SUCCESS';
export const UPDATE_POPUP                           = 'UPDATE_POPUP';
export const ADDRESS_POPUP                          = 'ADDRESS_POPUP';
export const SHIPPING_PREFERENCES_POPUP             = 'SHIPPING_PREFERENCES_POPUP';
export const ACCOUNT_SUMMARY_SUCCESS                = 'ACCOUNT_SUMMARY_SUCCESS';
export const ACCOUNT_STATEMENT_SUCCESS              = 'ACCOUNT_STATEMENT_SUCCESS';
export const ACCOUNT_STATEMENT_PAGINATION_SUCCESS   = 'ACCOUNT_STATEMENT_PAGINATION_SUCCESS';
export const ACCOUNT_STATEMENT_BREAKUP_SUCCESS      = 'ACCOUNT_STATEMENT_BREAKUP_SUCCESS';
//Footer
export const FOOTER_POPULAR_TAGS                    = 'FOOTER_POPULAR_TAGS';