export default {  
  logo: [],
  wishlist: [],
  cart: [],
  currency_data:false,
  userlogin: false,
  OptPopup:false,
  LoginPopup:false,
  RegisterPopup:false,
  SuccessPopup:false,
  DesignPopup:false,
  dialog_open:0,
  seller_agreement_data:[],
  custom_store:[],
  menus: [],
  information:false,
  banner:false,
  category_list:false,
  latest_product:false,
  trending_product:false,
  about_us:false,
  storelocator:false,
  contact_us:false,
  information_data:false,
  customer_type:false,
  country_list:false,
  path_keyword:false,
  category_filter_path:window.location.hash,
  category_data:false,
  filter_facets:false,
  rating_filter:false,
  price_with_currency:false,
  product_list:[],
  product_count:-1,
  product_detail_id:false,
  product_detail:false,
  product_detail_success:0,
  QuestionPopup:false,
  related_products:false,
  checkout_page:'./checkout/one_page_checkout#delivery',
  cart_api_suceess:0,
  cart_customer:false,
  cart_products:false,
  cart_total_sets:false,
  cart_total_pieces:false,
  cart_summary:false,
  clear_cart:false,
  cartlimitcross:false,
  cart_coupon:false,
  cart_weight:false,
  cart_is_empty:false,
  cart_language:false,
  ConfirmPopup:false,
  EstimatePopup:false,
  user_detail:[],
  bank_detail:[],
  address_list:[],
  form_detail:[],
  new_form_detail:[],
  account_success_message:'',
  order_details:false,
  orders:[],
  order_product_details:false,
  order_detail_info:false,
  order_product_detail:false,
  shipping_preferences:false,
  wish_list_product:[],
  recent_view_product_detail:[],
  post_req_menus:[],
  search_data:false,
  wishlist_product_detail:false,
  UpdatePopup:false,
  AddressPopup:false,
  ShippingPreferencesPopup:false,
  post_req_category:[],
  popular_tags:false,
  account_summary:false,
  account_statement:false,
  statement_orders:false,
  account_statement_breakup:false,
  breakup_debit:false,
  breakup_credit:false,
  breakup_balance:false,
  orders_breakup:false,
  breakup_is_positive_bal:false,
  policies:false,
  payment_methods:false,
  beyond_order_id:false,
  balance:false,
  important_note:false
}