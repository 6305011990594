import * as actionType from './ActionType';
import HeaderApi from '../api/HeaderApi';
import custom from '../custom/custom'
import $ from 'jquery'

export function logoData() {  
  return function(dispatch) {
    return HeaderApi.logo().then(response => {
      dispatch(HeaderLogoSuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function wishlistData() { 
  
  var customer_id = custom.getCookie('customer_id');
  var customer_access_token = custom.getCookie('customer_access_token');
  var wishlist_session_id = custom.getCookie('wishlist_session_id');  
  return function(dispatch) {
    return HeaderApi.wishlist(customer_id, customer_access_token, wishlist_session_id).then(response => {
      dispatch(HeaderWishlistSuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function cartData() {  
  var customer_id = custom.getCookie('customer_id');
  var customer_access_token = custom.getCookie('customer_access_token');
  var cart_session_id = custom.getCookie('cart_session_id');
  return function(dispatch) {
    return HeaderApi.cart(customer_id, customer_access_token, cart_session_id).then(response => {
      dispatch(HeaderCartSuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function userloginData() { 
  var customer_id = custom.getCookie('customer_id');
  var customer_access_token = custom.getCookie('customer_access_token'); 
  var country = custom.getUrlParameter('country');
  return function(dispatch) {
    return HeaderApi.userlogin(customer_id, customer_access_token, country).then(response => {
       custom.createCookie('user_country', response.data.user_country, 7);
       if(response.data.is_redirect)
       {
         window.location.href = response.data.redirect_url;
       }

       dispatch(HeaderUserloginSuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function currencyData() {  
  return function(dispatch) {
    return HeaderApi.currency().then(response => {
       dispatch(HeaderCurrencySuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function setCurrencySession(currency) {  
  return function(dispatch) {
    return HeaderApi.setCurrencySession(currency).then(response => {
       return response.data;
    }).catch(error => {
      throw(error);
    });
  };
}

export function searchAutocompleteData(term, type) { 
  return function(dispatch) {
    term = term.substring(0, 64);

    var response = get_keyword_search(term);
   if(response === null)
   { 
    return HeaderApi.searchAutocomplete(term, type).then(response => {
       if(type === 'trending')
       {
          var today = new Date();
          var today_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          var record = {value: JSON.stringify(response.trending), timestamp: today_date}
          localStorage.setItem('trending_search', JSON.stringify(record));
          return response.trending;
       }
       else
       {
        if(response.keywords && response.keywords.length > 0 ||
           response.category && response.category.length > 0)
           {
             save_keyword_search(term, response); 
           }
       }  
       
       dispatch(HeaderSearchSuccess(response));
    }).catch(error => {
      throw(error);
    });
   }
   else
   {
     dispatch(HeaderSearchSuccess(response));
   } 
  };
}

export function save_keyword_search(phrase, data)
{
   phrase = $.trim(phrase);
   if(phrase !== '')
   {   
     var self = this;
     var keyword_search = JSON.parse(localStorage.getItem('keyword_search'));
     if(!keyword_search) { keyword_search = []; }

     var today = new Date();
     var today_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
     keyword_search.unshift({phrase:phrase, data:data, timestamp:today_date});
     
     var finalAr = [];
     var currentPair;
     var pos = null;
     for(var i=0; i < keyword_search.length; i++) {
        currentPair = keyword_search[i];
        pos = null;
       
       for(var j=0; j < finalAr.length; j++){
       if(finalAr[j].phrase.toLowerCase() === currentPair.phrase.toLowerCase())
        {
            pos = j;
        }
      }
      if(pos === null){
        finalAr.push(currentPair);
       }
     }
     localStorage.setItem('keyword_search', JSON.stringify(finalAr));
   }
}

export function get_keyword_search(phrase)
{
  var search_data = null;
  var today = new Date();
  var today_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var keyword_search = JSON.parse(localStorage.getItem('keyword_search'));
  if(keyword_search)
  {
    for(var i=0; i < keyword_search.length; i++) 
    {
     var timestamp = keyword_search[i].timestamp;
     if(keyword_search[i].phrase.toLowerCase() === phrase.toLowerCase() && today_date === timestamp)
        {
           search_data = keyword_search[i].data;
        } 
    }
  }  
  return search_data; 
}

export function HeaderLogoSuccess(logo) {  
  return {type: actionType.HEADER_LOGO_SUCCESS, logo};
}

export function HeaderWishlistSuccess(wishlist) {  
  return {type: actionType.HEADER_WISHLIST_SUCCESS, wishlist};
}

export function HeaderCartSuccess(cart) {  
  return {type: actionType.HEADER_CART_SUCCESS, cart};
}

export function HeaderUserloginSuccess(userlogin) {  
  return {type: actionType.HEADER_USERLOGIN_SUCCESS, userlogin};
}

export function HeaderSearchSuccess(search_data) {  
  return {type: actionType.HEADER_SEARCH_SUCCESS, search_data};
}

export function HeaderCurrencySuccess(currency_data) {  
  return {type: actionType.HEADER_CURRENCY_SUCCESS, currency_data};
}
