import React from 'react'

const CookieDisable = () => (
       <div style={{margin:'5px'}}> 
          <h4>Please Enable Cookies to Continue</h4>
          <ul>
            <li>To continue shopping at wholesalebox, please enable cookies in your web browser</li>
            <li>Once you have enabled cookies in your browser, please reload page.</li>
          </ul>
       </div> 
)

export default CookieDisable
