import React, { Component } from 'react'
import SearchMenu from './search_menu'
import Dialog from '@material-ui/core/Dialog'
import Paper from 'material-ui/Paper'
import  './index.css'

class Search  extends Component {


	render(){
		
    return (
        <Dialog
             fullScreen={true}
             open={this.props.searchOpen}
             aria-labelledby="responsive-dialog-title"
             className="search_drawer"
            >
    	<div className="search_bar search_bar_drawer">
         <div className="search_full_box"> 
          <div className="nav_singles_store_btn nav_singles_store_btn_drawer"><span onClick={() => this.props.clickHandler(this)}> <i className="fa fa-long-arrow-left" aria-hidden="true"></i></span></div>
           <input value={this.props.search} type="text" id="search_input_box_drawer" placeholder="Search from more than 1 Lac Products..." onChange={this.props.searchHandler} maxLength="64" />
          <div className="nav_singles_store_cross_btn"><span onClick={() => this.props.clickHandler(this, '', 1)}> &times; </span></div>
         </div>
         <Paper className="search_menu_list" style={{boxShadow : "none",fontFamily: "Roboto, sans-serif"}}>
          
           {this.props.searchList.keywords && this.props.searchList.keywords.length > 0 && this.props.search.length > 2 ?
        	<SearchMenu clickHandler={this.props.clickHandler} searchList = {this.props.searchList} />
            : 
             this.props.searchList.category && this.props.searchList.category.length > 0 && this.props.search.length > 2 ?
             <SearchMenu clickHandler={this.props.clickHandler} searchList = {this.props.searchList} />
             :
             <SearchMenu clickHandler={this.props.clickHandler} searchList = {this.props.default_search} />
              }

    	</Paper>
        </div>
    </Dialog>
    )
	}
}


export default Search;