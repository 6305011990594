import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Api from '../../api/Api'

class SearchMenu  extends Component {
  
  constructor( props )
    {
        super( props );
    }

	render(){

        let searchList = this.props.searchList;

    return (
       <div className="auto_search_content">

       {searchList.recent && searchList.recent.length > 0 ?
                <div>
                     <ul className="search_list">
                     {searchList.recent.map((recent, index) => {
                           return (<li><Link onClick={() => this.props.clickHandler(this, recent)} to={Api.folder_path+'category#!filter=&price_filter=&option=&sort=sort_order&order=ASC&rating_filter=&search='+encodeURIComponent(recent.phrase)+'&phrase_id='+recent.phrase_id+'&category_id='+recent.category_id}>
                                       <i className='fa fa-history'></i> 
                                        {<span dangerouslySetInnerHTML={{ __html:  recent.phrase.length > 40 ? recent.phrase.substr(0, 40)+'...'  : recent.phrase }} />}
                                        {recent.category_name ?
                                         <div className='search_by_category'>in  {<span dangerouslySetInnerHTML={{ __html: recent.category_name }} />} </div >
                                         : ''}
                                       </Link>
                                   </li>)
                       }) 
                      }
                    </ul>
                </div>
                :''}
       

      {searchList.trending && searchList.trending.length > 0 ?
                <div>
                    <div className="search_title">Trending</div>
                     <ul className="search_list">
                     {searchList.trending.map((trending, index) => {
                           return (<li><Link onClick={() => this.props.clickHandler(this, trending)} to={Api.folder_path+'category#!filter=&price_filter=&option=&sort=sort_order&order=ASC&rating_filter=&search='+encodeURIComponent(trending)}>
                                       <i className='fa fa-search'></i> 
                                       {<span dangerouslySetInnerHTML={{ __html: trending.length > 40 ? trending.substr(0, 40)+'...' : trending }} />}
                                       </Link>
                                   </li>)
                       }) 
                      }
                    </ul>
                </div>
                :''}


       {searchList.category && searchList.category.length > 0 ?
                <div>
                     <ul className="search_list">
                     {searchList.category.map((category, index) => {
                           return (<li><Link onClick={() => this.props.clickHandler(this, category)} to={Api.folder_path+'category#!filter=&price_filter=&option=&sort=sort_order&order=ASC&rating_filter=&search='+encodeURIComponent(category.phrase)+'&phrase_id='+category.phrase_id+'&category_id='+category.category_id}>
                                       <i className='fa fa-search'></i> 
                                       {<span dangerouslySetInnerHTML={{ __html: category.phrase.length > 40 ? category.phrase.substr(0, 40)+'...' : category.phrase }} />}
                                       <div className='search_by_category'>in  {<span dangerouslySetInnerHTML={{ __html: category.category_name }} />} </div >
                                       </Link>
                                   </li>)
                       }) 
                      }
                    </ul>
                </div>
                :''}

        {searchList.keywords && searchList.keywords.length > 0 ?
                <div>
                    {searchList.category && searchList.category.length > 0  ?
                   <hr style={{margin:'0px'}} />
                   : ''}
                     <ul className="search_list">
                     {searchList.keywords.map((keywords, index) => {
                           return (<li><Link onClick={() => this.props.clickHandler(this, keywords)} to={Api.folder_path+'category#!filter=&price_filter=&option=&sort=sort_order&order=ASC&rating_filter=&search='+encodeURIComponent(keywords)}>
                                       <i className='fa fa-search'></i> 
                                       {<span dangerouslySetInnerHTML={{ __html: keywords.length > 40 ? keywords.substr(0, 40)+'...' : keywords }} />}
                                       </Link>
                                   </li>)
                       }) 
                      }
                    </ul>
                </div>
                :''}

      </div>
    )
	}
}

export default SearchMenu;